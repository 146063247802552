import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Form, Radio, RadioGroup } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

export const TableSelector = (props) => {
   const [table, setTable] = useState('COMBINED');
   const tables = props.tables
   const onChangeTable = (event) => {
      setTable(event);
      props.table(event);
   };

   useEffect(() => {
      
   }, []);

   return (
      tables?.length > 1 ?    
         <TableSelectorSC>
            <Form.Group controlId="tableList">
               <RadioGroup
                  name="tableList"
                  inline
                  appearance="picker"
                  value={table}
                  onChange={onChangeTable}
               >
                  <span style={styles.radioGroupLabel}>Table View: </span>
                  <Radio value={'COMBINED'}>Combined</Radio>
                  {tables.includes('mmb_edu2') ?
                     <Radio value={'EDU'}>EDU</Radio>
                  : null }
                  {tables.includes('philips_mri_monitoring_data') ?
                     <Radio value={'MAGNET'}>Magnet</Radio>
                  : null }
                  {tables.includes('stt_magnet') ?
                     <Radio value={'STT'}>STT</Radio>
                  : null }
                  {tables.includes('philips_mri_rmmu_history') ?
                     <Radio value={'RMMU_HISTORY'}>RMMU History</Radio>
                  : null }
                  {tables.includes('philips_mri_rmmu_short') ?
                     <Radio value={'RMMU_SHORT'}>RMMU Short</Radio>
                  : null }
                  {tables.includes('philips_mri_rmmu_long') ?
                     <Radio value={'RMMU_LONG'}>RMMU Long</Radio>
                  : null }
               </RadioGroup>
            </Form.Group>
         </TableSelectorSC>
      : null
   );
};

const TableSelectorSC = styled.div`
   padding: 1rem;
`;

const styles = {
   radioGroupLabel: {
      padding: '8px 2px 8px 10px',
      display: 'inline-block',
      verticalAlign: 'middle',
   },
};
