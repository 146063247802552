import React, { useState, useEffect } from 'react';
import { FlexboxGrid, Tooltip, Whisper } from 'rsuite';
import styled from 'styled-components';
import 'rsuite/dist/rsuite.min.css';
import { DateTime } from 'luxon';
import { SeverityAlert } from '../../../../appHelpers/appCommonSC';

export const MagnetHeader = ({metadata, magnetData, dataConfig, alertConfigs}) => {
   const [recentValues, setRecentValues] = useState ([])

   const getMostRecentParameters = () => {
      const foundFields = {};
      const recentValue = [];

      // Loop through the data array
      magnetData?.dataTable.map(obj => {
         for (const field in obj) {
            // Check if the field is null and hasn't been found before
            if (obj[field] !== null && !foundFields[field]) {
               // Store the field and datetime in the result array
               recentValue.push({ field_name: field, field_value: obj[field], recent_datetime: obj.capture_datetime });            
               // Mark the field as found
               foundFields[field] = true;
            }
         } 
      })    
      setRecentValues(recentValue);
   }
   
   useEffect(() => {
      getMostRecentParameters();
   }, [magnetData]);

   return (
      <MagnetHeaderSC>
         <FlexboxGrid justify="space-around">
            {dataConfig[0]?.dataLabels?.map(label => (
               magnetData?.filterConfig?.map(config => (
                  config[2] === 1 && config[0] === label.pgColumn ?             
                     <Whisper trigger="hover"
                        speaker={
                           <Tooltip>{recentValues?.filter(value => value.field_name === label.pgColumn).map(item => (
                              `Last Read: ${DateTime.fromISO(item.recent_datetime).toFormat(
                                 'ff'
                              )}`
                        ))}</Tooltip>
                     }>
                        <FlexboxGrid.Item colspan={2}>
                           <ValueHeader>
                              {label.uiLabel}
                           </ValueHeader>

                           {alertConfigs?.filter(config => config.field_name === label?.pgColumn && config.user_id !== 'default').length > 0 ?
                              label?.pgColumn === 'comp_vib_status' ?
                                 recentValues?.filter(value => value.field_name === label.pgColumn).map(item => (
                                    item.field_value === null ? '-'
                                    : item.field_value === 1 ? <SeverityAlert >ON</SeverityAlert>
                                    : <SeverityAlert severity='high'>OFF</SeverityAlert>
                                 ))
                              : (label?.pgColumn === 'he_level_1_value' || label?.pgColumn === 'he_level_value') && metadata.he_tank_volume && magnetData?.chartConfig?.filter(config => (config.pgcolumn === label.pgColumn))[0]?.unit.toUpperCase() === 'LTRS' ? 
                                 recentValues?.filter(value => value.field_name === label.pgColumn).map(item => (
                                    item.field_value === null ? '-'
                                    : displayHeliumSeverityAlert(alertConfigs.filter(config => config.field_name === label.pgColumn && config.user_id !== 'default'), recentValues?.filter(value => value.field_name === label.pgColumn).map(item => (
                                       item.field_value
                                    )), magnetData?.chartConfig, metadata.he_tank_volume) 
                                 ))
                              :
                              displaySeverityAlert(alertConfigs.filter(config => config.field_name === label.pgColumn && config.user_id !== 'default'), recentValues?.filter(value => value.field_name === label.pgColumn).map(item => (
                                 item.field_value
                              )), magnetData?.chartConfig)             
                           : 
                           <>
                              {recentValues?.filter(value => value.field_name === label.pgColumn).map(item => (
                                 ((label?.pgColumn === 'he_level_1_value' || label?.pgColumn === 'he_level_value') && metadata.he_tank_volume && magnetData?.chartConfig?.filter(config => (config.pgcolumn === label.pgColumn))[0]?.unit.toUpperCase() === 'LTRS') ?          
                                 `${((parseFloat(item.field_value) / parseFloat(metadata.he_tank_volume) * 100).toFixed(2))} %`
                                 : 
                                 `${item.field_value} ${magnetData?.chartConfig?.filter(config => (config.pgcolumn === label.pgColumn))[0]?.unit ? magnetData?.chartConfig?.filter(config => (config.pgcolumn === label.pgColumn))[0].unit : ''}`
                              ))}
                           </>
                           }
                        </FlexboxGrid.Item>
                     </Whisper>  
                  : null 
               ))))}
         </FlexboxGrid>
      </MagnetHeaderSC>
   );
};

const displaySeverityAlert = (configs, rowValue, chartConfig) => {
   let mediumAlert = null;
   let unit = null;

   for (let config of configs) {
      if (((config.operator === 'less_than' && parseFloat(rowValue) < parseFloat(config.threshold)) ||
         (config.operator === 'greater_than' && parseFloat(rowValue) > parseFloat(config.threshold)) ||
         (config.operator === 'equals' && parseFloat(rowValue) === parseFloat(config.threshold))) && rowValue !== null) {
         if (config.severity === 'high') {
         return <SeverityAlert severity="high">{rowValue} {chartConfig?.filter(configUnit => configUnit?.pgcolumn === config.field_name)?.map(unit => unit.unit)}</SeverityAlert>;
         } else if (config.severity === 'medium') {
         mediumAlert = <SeverityAlert severity="medium">{rowValue} {chartConfig?.filter(configUnit => configUnit?.pgcolumn === config.field_name)?.map(unit => unit.unit)}</SeverityAlert>;
         }
      }
      unit = chartConfig?.filter(configUnit => configUnit?.pgcolumn === config.field_name)?.map(unit => unit.unit);
   }

   if (mediumAlert) {
      return mediumAlert;
   } else {
      return `${rowValue} ${unit}`
   }
};

const displayHeliumSeverityAlert = (configs, rowValue, chartConfig, tankVolume) => {
   let mediumAlert = null;
   let unit = null;

   for (let config of configs) {
     if ((config.operator === 'less_than' && (parseFloat(rowValue) / parseFloat(tankVolume) * 100).toFixed(2) < parseFloat(config.threshold)) ||
        (config.operator === 'greater_than' && (parseFloat(rowValue) / parseFloat(tankVolume) * 100).toFixed(2) > parseFloat(config.threshold)) ||
        (config.operator === 'equals' && parseFloat(rowValue) === parseFloat(config.threshold))) {
       if (config.severity === 'high') {
         return <SeverityAlert severity="high">{(parseFloat(rowValue) / parseFloat(tankVolume) * 100).toFixed(2)} % </SeverityAlert>;
       } else if (config.severity === 'medium') {
         mediumAlert = <SeverityAlert severity="medium">{(parseFloat(rowValue) / parseFloat(tankVolume) * 100).toFixed(2)} % </SeverityAlert>;
       }
     }
     unit = chartConfig?.filter(configUnit => configUnit.pgcolumn === config.field_name)?.map(unit => unit.unit);
   }
 
   if (mediumAlert) {
    return mediumAlert;
   } else {
    return `${(parseFloat(rowValue) / parseFloat(tankVolume) * 100).toFixed(2)} %`
   }
 };


const MagnetHeaderSC = styled.div`
   display: block;
   width: 89vw;
   text-align: center;
`

const ValueHeader = styled.div`
   display: block;
   font-size: 0.8em;
   color: #97969B;
   font-weight: normal;

   @media screen and (max-width: 769px) {
      font-size: 0.8em;
      font-weight: normal;
   }
`;

