import React, { useEffect, useState, useRef } from 'react';
import { Whisper, Popover, Tooltip} from 'rsuite';
import styled from 'styled-components';
import 'rsuite/dist/rsuite.min.css';
import InfoOutlineIcon from '@rsuite/icons/InfoOutline';
import RemindFillIcon from '@rsuite/icons/RemindFill';
import { DateTime, Interval } from 'luxon';

export const RecentValuesFC = ({ recent_values, alertConfigs, tank }) => {
   const [heliumValue, setHeliumValue] = useState(recent_values?.helium_value);
   const [heliumUnits, setHeliumUnits] = useState(recent_values?.helium_units);
   const triggerRef = useRef();
   const [heliumAlert, setHeliumAlert] = useState();
   const [pressureAlert, setPressureAlert] = useState();
   const [heliumSpeaker, setHeliumSpeaker] = useState();
   const [pressureSpeaker, setPressureSpeaker] = useState();
   const [heliumModified, setHeliumModified] = useState(false);

   const modifierSpeaker = (
      <Tooltip>
         <>
            Helium Converted to Percent: <br/> Actual Reading: {recent_values?.helium_value} {recent_values?.helium_units}.
         </>
      </Tooltip>
   );

   const oldHeliumSpeaker = (
      <Tooltip>
         <>
            Helium Level Last Read: <br/> {DateTime.fromISO(recent_values?.helium_datetime).toLocaleString(DateTime.DATETIME_MED)}.
         </>
      </Tooltip>
   )

   const oldPressureSpeaker = (
      <Tooltip>
         <>
            Pressure Level Last Read: <br/> {DateTime.fromISO(recent_values?.pressure_datetime).toLocaleString(DateTime.DATETIME_MED)}.
         </>
      </Tooltip>
   )

   const setPressureAlerts = () => {   
      if (alertConfigs) {
         let highestSeverity = 'low';
         let pressureConfig = null;

         for (const config of alertConfigs) {
            if(config.field_name.includes('pressure') || config.field_name.includes('he_psi') ) {
               if(((parseFloat(recent_values?.pressure_value) < config.threshold) && config.operator === 'less_than')
                  || ((parseFloat(recent_values?.pressure_value) > config.threshold) && config.operator === 'greater_than')) {
                  if (highestSeverity !== 'high') {
                     highestSeverity = config.severity; // Update the highest severity
                     pressureConfig = config   // Update highest config
                  }
               }
            }
         }

         if (pressureConfig) {
            setPressureAlert(highestSeverity);
            setPressureSpeaker(
               <Popover title={<AlertText severity={pressureConfig.severity}>{pressureConfig.severity?.charAt(0).toUpperCase() + pressureConfig.severity?.slice(1) + ' Pressure Alert'}</AlertText>}>
                  {`${recent_values?.pressure_value} ${pressureConfig.threshold_units} ${pressureConfig.operator.replace(/_/g, ' ')} ${pressureConfig.threshold} ${pressureConfig.threshold_units}`}
               </Popover>
            );
         }
      }
   }

   const setHeliumAlerts = () => {
      let modifiedHelium = recent_values?.helium_value;

      if(tank && recent_values?.helium_units?.toUpperCase() === 'LTRS') {
         setHeliumUnits('%')
         setHeliumValue(parseFloat(((recent_values.helium_value / tank) * 100)).toFixed(2));
         setHeliumModified(true);
         modifiedHelium = parseFloat(((recent_values.helium_value / tank) * 100)).toFixed(2);
      }

      if (alertConfigs) {
         let highestSeverity = 'low';
         let heliumConfig = null;

         for (const config of alertConfigs) {
            if((config.field_name.includes('he_level') || config.field_name.includes('helium_level')) && config.operator === 'less_than') {
               if(modifiedHelium < config.threshold) {
                  if (highestSeverity !== 'high') {
                     highestSeverity = config.severity; // Update the highest severity
                     heliumConfig = config   // Update highest config
                  }
               }
            }
         }

         if (heliumConfig) {
            setHeliumAlert(highestSeverity);
            setHeliumSpeaker(
               <Popover title={<AlertText severity={heliumConfig.severity}>{`${heliumConfig.severity.charAt(0).toUpperCase()}${heliumConfig.severity.slice(1)} Helium Alert`}</AlertText>}>
                  {`${modifiedHelium} ${heliumConfig.threshold_units} ${heliumConfig.operator.replace(/_/g, ' ')} ${heliumConfig.threshold} ${heliumConfig.threshold_units}`}
               </Popover>
            );
         }
      }
   }

   useEffect(() => {
      setHeliumAlerts();
      setPressureAlerts();
   }, [recent_values, alertConfigs]);

   return (
      recent_values ? 
         <>
            {recent_values?.pressure_value ? 
               <DataBox>
                  {(Date.now() - new Date(recent_values.pressure_datetime).getTime()) > (24 * 60 * 60 * 1000) 
                     ? <Whisper placement="right" speaker={oldPressureSpeaker} ref={triggerRef} trigger="hover" key={'old_pressure'}>
                        <RemindFillIcon color='orange' style={{float: 'right', marginTop: '3%'}}/>
                     </Whisper>
                  : null}
                  <LabelText>Pressure: </LabelText>
                  {pressureAlert ?
                     <Whisper placement="right" speaker={pressureSpeaker} ref={triggerRef} trigger="hover" key={'helium'}>
                        <SmallText severity={pressureAlert}>
                           {recent_values.pressure_units?.toLowerCase() === 'mbar' ?
                              parseFloat(recent_values?.pressure_value).toFixed(2)
                           : parseFloat(recent_values?.pressure_value).toFixed(3)
                           }
                        </SmallText>
                     </Whisper>
                  : <SmallText severity={pressureAlert}>
                        {recent_values.pressure_units?.toLowerCase() === 'mbar' ?
                              parseFloat(recent_values?.pressure_value).toFixed(2)
                        : parseFloat(recent_values?.pressure_value).toFixed(3)
                        }
                     </SmallText>
                  }                  
                  <UnitText>{recent_values.pressure_units}</UnitText>
               </DataBox> 
            : null }                  
            {heliumValue ? 
               <DataBox>
                   {(Date.now() - new Date(recent_values.helium_datetime).getTime()) > (24 * 60 * 60 * 1000) 
                     ? 
                     <Whisper placement="right" speaker={oldHeliumSpeaker} ref={triggerRef} trigger="hover" key={'old_helium'}>
                        <RemindFillIcon color='orange' style={{float: 'right', marginTop: '3%'}}/>
                     </Whisper>
                     : null
                  }
                  <LabelText>
                     { heliumModified ? 
                        <Whisper placement="top" controlId="control-id-click" trigger="hover" speaker={modifierSpeaker}>
                           <InfoOutlineIcon color='green'/>
                        </Whisper> : null} HE Level: 
                  </LabelText>
                  {heliumAlert ?
                     <Whisper placement="right" speaker={heliumSpeaker} ref={triggerRef} trigger="hover" key={'helium'}>
                        <SmallText severity={heliumAlert}>{parseFloat(heliumValue).toFixed(2)}</SmallText>
                     </Whisper>
                  :
                     <SmallText severity={heliumAlert}>{parseFloat(heliumValue).toFixed(2)}</SmallText>
                  }            
                  <UnitText>{heliumUnits}</UnitText>
               </DataBox> 
            : null }                   
         </>
      : null
   )
};

const DataBox = styled.div`
   display: block;
   background-color: ${({color}) => color};
   color: ${({color}) => color === 'red' ? 'white' : ''};
`

const LabelText = styled.div `
   width: 70px;
   display:inline-block;
   font-size: 0.8em;
   font-weight: normal;
   color: #97969B;

   @media screen and (max-width: 769px) {
      width: 100%;
      font-size: 0.8em;
   }
`;

const SmallText = styled.div `
   display:inline-block;
   padding-left: 1.5%;
   padding-right: 1.5%;
   margin: 1%;
   border-radius: 5px;
   
   color: ${({ severity }) =>  severity === 'high' ? '#e51b1d;' 
      : severity === 'medium' ?  '#fda005'
      : severity === 'low' ? 'darkgrey'
      : ''};
   background-color: ${({ severity }) =>  severity === 'high' ? '#fff2f2;' 
      : severity === 'medium' ?  '#ffeecc'
      : severity === 'low' ? '#e6e5e3'
      : ''};

   @media screen and (max-width: 769px) {
      width: 100%;
      font-size: 0.8em;
   }
`;

const UnitText = styled.div `
   padding-left: 2%;
   display:inline-block;
   font-size: 0.7em;
   font-weight: normal;
   color: #97969B;

   @media screen and (max-width: 769px) {
      width: 100%;
      font-size: 0.7em;
   }
`;

const AlertText = styled.div `
   color: ${({ severity }) =>  severity === 'high' ? '#e51b1d;' 
      : severity === 'medium' ?  '#fda005'
      : severity === 'low' ? 'darkgrey'
      : ''};
   background-color: ${({ severity }) =>  severity === 'high' ? '#fff2f2;' 
      : severity === 'medium' ?  '#ffeecc'
      : severity === 'low' ? '#e6e5e3'
      : ''};
   padding: 1.5%;
   font-size: 0.95em;
   text-align: center;
`;