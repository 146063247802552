import { DateTime } from 'luxon';
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { SeverityAlert } from '../../../../../appHelpers/appCommonSC';

export const TableFC = ({metadata, dataTable, dataConfig, filterLevel, alertConfigs, tableSwitch}) => {
   let chartConfig = dataTable[0].chartConfig;
   let columnConfig = dataConfig[0].dataLabels;
   let columns = columnConfig.map(col => (col.pgColumn));
   let columnWidth = 125;
   let displayDataTable = dataTable[0].dataTable;
   let data = dataTable[0].dataTable;
   const [filterConfig, setFilteredConfig] = useState(dataTable[0].filterConfig)
   const [filteredDataLabel, setFilteredDataLabel] = useState(dataConfig[0].dataLabels)
   const { dataLabels } = dataConfig[0];
   const [tableRows, setTableRows] = useState(dataTable[0].dataTable);
   const rowHeight = 40;
   const visibleRowCount = 25;
   const [scrollTop, setScrollTop] = useState(0);
   const containerRef = useRef(null);
   const totalHeight = data?.length * rowHeight;
   const startRow = Math.floor(scrollTop / rowHeight);
   const endRow = startRow + visibleRowCount;
   let visibleData = tableRows.slice(startRow, endRow);

   const removeUnusedColumns = () => {
      const dataTableKeys = Object.keys(displayDataTable[0]);
      setFilteredDataLabel(dataLabels.filter(label => dataTableKeys.includes(label.pgColumn)));
   }

   const filterColumns = () => {
      let dataTableKeys = Object.keys(displayDataTable[0]);
      const sttTableKeys = ['he_level_value', 'he_pressure_value', 'he_boil_off_value']
      const eduTableKeys = ['temp_probe_1_value', 'temp_probe_0_value', 'room_humidity_value', 'room_temp_value', 'comp_vib_status'];
      const rmmuTableKeys = ['avgpwr', 'minpwr', 'maxpwr', 'minpr', 'maxpr', 'lhe1', 'lhe2', 'dps', 'talm', 'palm', 'cres']
      const rmmuShortTableKeys = ['avgpwr', 'minpwr', 'maxpwr', 'minpr', 'avgpr', 'maxpr', 'lhe1', 'lhe2', 'dps', 'talm', 'palm', 'cres', 'avgabs_value', 'cerr_state', 'compressorreset_state', 'chd_value', 'cpr_value']

      let filterLevelArray = [1, 2, 3 , 4];

      if (tableSwitch === 'EDU') {
         dataTableKeys = dataTableKeys.filter(item => eduTableKeys.includes(item));
      } else if (tableSwitch === 'MAGNET') {
         dataTableKeys = dataTableKeys.filter(item => !eduTableKeys.includes(item) && !rmmuTableKeys.includes(item) && !rmmuShortTableKeys.includes(item) && !sttTableKeys.includes(item));
      } else if (tableSwitch === 'RMMU_HISTORY') {
         dataTableKeys = dataTableKeys.filter(item => rmmuTableKeys.includes(item));
      } else if (tableSwitch === 'RMMU_SHORT' || tableSwitch === 'RMMU_LONG' ) {
         dataTableKeys = dataTableKeys.filter(item => rmmuShortTableKeys.includes(item));
      } else if (tableSwitch === 'STT') {
         dataTableKeys = dataTableKeys.filter(item => sttTableKeys.includes(item));
      }

      if (filterLevel === 1) {
         filterLevelArray = [1];
      } else if (filterLevel === 2) {
         filterLevelArray = [1,2]
      } else if (filterLevel === 3) {
         filterLevelArray = [1, 2, 3]
      }

      if (filterLevel !== 4) {
         let tempConfig = filterConfig?.filter(config => filterLevelArray.includes(config[2]) && dataTableKeys.includes(config[0]));
         let pgColumns = tempConfig?.map(config => config[0]);

         if (pgColumns?.length > 0) {
            setFilteredDataLabel(dataLabels.filter(label => pgColumns.includes(label.pgColumn)));
        } else {
            setFilteredDataLabel(dataLabels.filter(label => dataTableKeys.includes(label.pgColumn)));
        }
      } else {
         setFilteredDataLabel(dataLabels.filter(label => dataTableKeys.includes(label.pgColumn)));
      }
   }

   const filterTableRows = () => {
      if(tableSwitch === 'COMBINED') {
         setTableRows(dataTable[0].dataTable);
      } else if (tableSwitch === 'EDU'){
         setTableRows(tableRows.filter(row => row.table_name === 'edu'))
      } else if (tableSwitch === 'MAGNET'){
         setTableRows(tableRows.filter(row => row.table_name === 'philips_mri'))
      } else if (tableSwitch === 'STT'){
         setTableRows(tableRows.filter(row => row.table_name === 'stt_magnet'))
      } else if (tableSwitch === 'RMMU_HISTORY'){
         setTableRows(tableRows.filter(row => row.table_name === 'rmmu_history'))
      } else if (tableSwitch === 'RMMU_SHORT'){
         setTableRows(tableRows.filter(row => row.table_name === 'rmmu_short'))
      } else if (tableSwitch === 'RMMU_LONG'){
         setTableRows(tableRows.filter(row => row.table_name === 'rmmu_long'))
      }
   }

   const handleScroll = (e) => {
      setScrollTop(e.currentTarget.scrollTop);
   };

   useEffect(() => {
      removeUnusedColumns();
   }, [dataTable]);

   useEffect(() => {
      filterColumns();    
   }, [filterLevel]);

   useEffect(() => {
      filterTableRows();
   }, [tableSwitch]);
   
   return (
      <TableSC minWidth={(columnWidth * columns.length) + 250}> 
         <Table minWidth={(columnWidth * columns.length) + 250}>
         
         {/* Column Headers */}
         <TableHeader>
            <CellHeaderAlign key={'datetimeKey'} columnWidth = {175}>
            {'DateTime'}
            </CellHeaderAlign>
            {filteredDataLabel.map((col, colIndex) => (
               <CellHeader key={colIndex} columnWidth = {columnWidth}>
               {col.uiLabel}
               </CellHeader>
            ))}
         </TableHeader>

         {/* Table Body */}
         <div
            ref={containerRef}
            onScroll={handleScroll}
            style={{
               height: `58vh`,
               overflowY: 'auto',
            }}
         >
            <div
               style={{
               height: `${totalHeight}px`,
               position: 'relative'
               }}
            >
               {visibleData.map((row, rowIndex) => (
               <div
                  key={rowIndex}
                  style={{
                     position: 'absolute',
                     top: `${(startRow + rowIndex) * rowHeight}px`,
                     height: `${rowHeight}px`,
                     width: `${columnWidth * columns.length + 175}px`,
                     display: 'flex',
                  }}
               >
                  <CellLeftAlign key={`datetime-${rowIndex}`} columnWidth = {175}>
                     {DateTime.fromISO(row.capture_datetime).toFormat(
                        'ff'
                  )}
                  </CellLeftAlign>
                  {filteredDataLabel.map((col, colIndex) => (
                     
                     col.pgColumn === 'comp_vib_status' ?
                        <Cell key={colIndex} columnWidth = {columnWidth}>
                           {row[col.pgColumn] === null ? '-' : row[col.pgColumn] === 1 ? 'ON' : <SeverityAlert severity='high'>OFF</SeverityAlert>} 
                        </Cell>
                     : col.pgColumn === 'temp_probe_0_value' ||  col.pgColumn === 'temp_probe_1_value' ?
                        <Cell key={colIndex} columnWidth = {columnWidth}>
                            {row[col.pgColumn] <= 0 ? '-' : `${row[col.pgColumn]} ${chartConfig?.filter(configUnit => configUnit.pgcolumn === col.pgColumn)?.map(unit => unit.unit)}`}
                        </Cell>
                     : (col.pgColumn === 'he_level_1_value' || col.pgColumn === 'he_level_value') && (chartConfig?.filter(config => config.pgcolumn === col.pgColumn)[0].unit === 'LTRS' && metadata.he_tank_volume) ?
                        <Cell key={colIndex} columnWidth = {columnWidth}>
                           {displayHeliumSeverityAlert(alertConfigs.filter(config => config.field_name === col.pgColumn && config.user_id !== 'default'), row[col.pgColumn], chartConfig, metadata.he_tank_volume)}
                        </Cell>
                     : row[col.pgColumn] || row[col.pgColumn] === 0 ?
                        <Cell key={colIndex} columnWidth = {columnWidth}>
                           {alertConfigs.filter(config => config.field_name === col.pgColumn && config.user_id !== 'default').length > 0 ?
                              displaySeverityAlert(alertConfigs.filter(config => config.field_name === col.pgColumn && config.user_id !== 'default'), row[col.pgColumn], chartConfig)
                           :
                           `${row[col.pgColumn]} ${chartConfig?.filter(config => config.pgcolumn === col.pgColumn)?.map(unit => unit.unit)}`
                           }
                        </Cell>
                     : <Cell key={colIndex} columnWidth = {columnWidth}>
                           -
                        </Cell>
                  ))}
               </div>
               ))}
            </div>
         </div>
         </Table>
      </TableSC>
   )
};

const displaySeverityAlert = (configs, rowValue, chartConfig) => {
  let mediumAlert = null;
  let unit = null;
 
  for (let config of configs) {
    if ((config.operator === 'less_than' && parseFloat(rowValue) < parseFloat(config.threshold)) ||
       (config.operator === 'greater_than' && parseFloat(rowValue) > parseFloat(config.threshold)) ||
       (config.operator === 'equals' && parseFloat(rowValue) === parseFloat(config.threshold))) {
      if (config.severity === 'high') {
        return <SeverityAlert severity="high">{rowValue} {chartConfig?.filter(configUnit => configUnit.pgcolumn === config.field_name)?.map(unit => unit.unit)}</SeverityAlert>;
      } else if (config.severity === 'medium') {
        mediumAlert = <SeverityAlert severity="medium">{rowValue} {chartConfig?.filter(configUnit => configUnit.pgcolumn === config.field_name)?.map(unit => unit.unit)}</SeverityAlert>;
      }
    }
    unit = chartConfig?.filter(configUnit => configUnit.pgcolumn === config.field_name)?.map(unit => unit.unit);
  }

  if (mediumAlert) {
   return mediumAlert;
  } else {
   return `${rowValue} ${unit}`
  }
};

const displayHeliumSeverityAlert = (configs, rowValue, chartConfig, tankVolume) => {
   let mediumAlert = null;
   let unit = null;

   for (let config of configs) {
     if ((config.operator === 'less_than' && (parseFloat(rowValue) / parseFloat(tankVolume) * 100).toFixed(2) < parseFloat(config.threshold)) ||
        (config.operator === 'greater_than' && (parseFloat(rowValue) / parseFloat(tankVolume) * 100).toFixed(2) > parseFloat(config.threshold)) ||
        (config.operator === 'equals' && parseFloat(rowValue) === parseFloat(config.threshold))) {
       if (config.severity === 'high') {
         return <SeverityAlert severity="high">{(parseFloat(rowValue) / parseFloat(tankVolume) * 100).toFixed(2)} % </SeverityAlert>;
       } else if (config.severity === 'medium') {
         mediumAlert = <SeverityAlert severity="medium">{(parseFloat(rowValue) / parseFloat(tankVolume) * 100).toFixed(2)} % </SeverityAlert>;
       }
     }
     unit = chartConfig?.filter(configUnit => configUnit.pgcolumn === config.field_name)?.map(unit => unit.unit);
   }
 
   if (mediumAlert) {
    return mediumAlert;
   } else {
    return `${(parseFloat(rowValue) / parseFloat(tankVolume) * 100).toFixed(2)} %`
   }
 };

const TableSC  = styled.div`
   width: 90vw;
   overflow-x: auto;
`

const Table  = styled.div`
   min-width: ${({ minWidth }) => minWidth ? `${minWidth}px` : '90vw'};
`

const TableHeader = styled.div`
   padding-top: 10px;
   padding-bottom: 10px;
   display: flex;
   color: white;
   background-color: rgba(0, 91, 148);
`;

const Cell = styled.div`
   text-align: center;
   width: ${({ columnWidth }) => columnWidth ? `${columnWidth}px` : '100px'};
   border-bottom: 1px solid #f2f2f5;
   padding-top: 10px;
   padding-bottom: 10px;
`

const CellLeftAlign = styled.div`
   width: ${({ columnWidth }) => columnWidth ? `${columnWidth}px` : '100px'};
   border-bottom: 1px solid #f2f2f5;
   padding-top: 10px;
   padding-bottom: 10px;
   padding-left: 10px;
`
const CellHeader = styled.div`
   text-align: center;
   width: ${({ columnWidth }) => columnWidth ? `${columnWidth}px` : '100px'};
   padding-top: 4px;
   padding-bottom: 4px;
`

const CellHeaderAlign = styled.div`
   width: ${({ columnWidth }) => columnWidth ? `${columnWidth}px` : '100px'};
   padding-top: 4px;
   padding-bottom: 4px;
   padding-left: 10px;
`